<template>
	<div class="p-fluid" style="width: 100%; max-width: 300px">
		<div class="p-field p-d-flex p-jc-left p-ai-center">
			<InputSwitch id="visible" @click="changeValue" v-model="admin" />
			<div class="p-as-center p-ml-2">Administrateur<br><small class="p-error"><b>ATTENTION DANGER</b></small></div><br>
		</div>

		<div class="p-field p-d-flex p-jc-left p-ai-center">
			<InputSwitch id="visible" @click="changeValue" v-model="supervisor" />
			<div class="p-as-center p-ml-2">Superviseur<br><small class="text-muted">peut avoir des sous-utilisateurs</small></div><br>
		</div>

		<div class="p-field p-d-flex p-jc-left p-ai-center">
			<InputSwitch id="visible" @click="changeValue" v-model="geoloc" />
			<div class="p-as-center p-ml-2">Géolocalisation<br><small class="text-muted">dispose des boutons Google Map, Waze, etc..</small></div><br>
		</div>

		<div class="p-field p-d-flex p-jc-left p-ai-center">
			<InputSwitch id="visible" @click="changeValue" v-model="calendar" />
			<div class="p-as-center p-ml-2">Prise de RDV<br><small class="text-muted">accès calendrier et synchronisation Google Agenda</small></div><br>
		</div>

		<div class="p-field p-d-flex p-jc-left p-ai-center">
			<InputSwitch id="visible" @click="changeValue" v-model="media" />
			<div class="p-as-center p-ml-2">Médias<br><small class="text-muted">peut uploader et stocker des médias</small></div><br>
		</div>
	</div>
</template>

<script>
	const ROLES = {
		admin: 'ROLE_ADMIN',
		supervisor: 'ROLE_SUPERVISOR',
		geoloc: 'ROLE_GEOLOC',
		calendar: 'ROLE_CALENDAR',
		media: 'ROLE_MEDIA',
	};

export default {
	data(){
		return {
			admin: false,
			supervisor: false,
			geoloc: false,
			calendar: false,
			media: false,
			innerValue: [],
		}
	},
	props: {
		value: {}
	},
	created(){
		if(this.value === undefined){
			return;
		}
		this.admin = this.value.includes(ROLES.admin);
		this.supervisor = this.value.includes(ROLES.supervisor);
		this.geoloc = this.value.includes(ROLES.geoloc);
		this.calendar = this.value.includes(ROLES.calendar);
		this.media = this.value.includes(ROLES.media);
	},
	methods: {
		changeValue(){
			this.$nextTick(() => {
				this.updateAll();
			})
		},
		computeInner(){
			let arr = [];
			if(this.admin){
				arr.push(ROLES.admin);
			}

			if(this.supervisor){
				arr.push(ROLES.supervisor);
			}

			if(this.geoloc){
				arr.push(ROLES.geoloc);
			}

			if(this.calendar){
				arr.push(ROLES.calendar);
			}

			if(this.media){
				arr.push(ROLES.media);
			}

			this.innerValue = arr;
		},
		updateAll(){
			this.computeInner();
			this.$emit('input', this.innerValue);
		}
	},
	computed: {

	},
	mixins: [],
}
</script>

<style scoped>

</style>
