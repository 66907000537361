<template>
	<div class="p-grid">
		<div class="p-col-12">
			<h1>Utilisateurs de l'application</h1>
			<p class="text-muted">Tous les utilisateurs de Freecard sont affichés ici !</p>
		</div>
		<div class="p-col-12">
			<div class="card">
				<UsersList></UsersList>
			</div>
		</div>
	</div>
</template>

<script>
	import UsersList from '../components/app/superadmin/UsersList.vue'
export default {

		components: {
			UsersList,
		}
}
</script>

<style scoped>

</style>
