<template>
	<div style="width: 100%" class="selector input-field">
		<div v-if="dropdownValues !== undefined" class="w-100 selector-inner">
			<Dropdown v-model="innerValue" id="stylesheets" @change="updateValue(e)"
                      :options="dropdownValues" optionLabel="name" placeholder="Sélectionnez un plan" />
		</div>
	</div>
</template>

<script>
import PlanService from "../../../service/PlanService";

export default {
	data() {
		return {
			dropdownValues: undefined,
			innerValue: undefined,
		}
	},
	props: {
		value: {}
	},
	created() {
		this.planService = new PlanService();
	},
	mounted() {
		const vm = this;
		this.innerValue = this.value;

		this.planService.listPlans()
				.then(res => {
					let changed= false;
					if(res.error === true){
						throw new Error(res.message);
					}

					let arr = [
						{
							name: "Aucun",
							code: null,
						}
					];

					res.data['hydra:member'].forEach(function(item){
						arr.push({
							name: item.name,
							code: item['@id'],
						});
						if(item['@id'] === vm.innerValue){
                            vm.innerValue = {
                                name: item.name,
                                code: item['@id'],
                            };
							changed = true;
                        }
					});

					if(!changed){
						vm.innerValue = {
							name: "Aucun",
							code: null,
						}
					}

					vm.dropdownValues = arr;
				})
				.catch(err => {
					console.error(err.message);
				})
	},
	methods: {
		updateValue() {
			this.$emit('input', this.innerValue.code)
		}
	},
	computed: {

	}
}
</script>

<style scoped>

</style>
