<template>
	<div class="p-fluid user-profil">
		<div  v-if="loading || profile === undefined" class="p-fluid p-field p-d-flex p-jc-center p-ai-center">
			<ProgressSpinner />
		</div>
		<div v-else class="p-fluid">
			<div class="p-fluid">
				<h5>Informations</h5>
				<div class="p-fluid p-m-3">
					<div class="p-field">
						<label for="lastname">Nom</label>
						<InputText v-model="profile.lastname" readonly id="lastname" type="text" />
					</div>

					<div class="p-field">
						<label for="firstname">Prénom</label>
						<InputText v-model="profile.firstname" readonly id="firstname" type="text" />
					</div>

					<div class="p-field">
						<label for="company">Société</label>
						<InputText v-if="!lockedProps.company" v-model="info.company" readonly id="company" type="text" />
						<InputText v-else v-model="lockedProps['@values'].company" disabled id="company" type="text" />
					</div>

					<div class="p-field">
						<label for="poste">Poste</label>
						<InputText v-model="profile.poste" readonly id="poste" type="text" />
					</div>


					<div class="p-field">
						<label for="phone1">Tel. 1</label>
						<InputText v-if="!lockedProps.phone1" v-model="info.phone1" readonly id="phone1" type="text" />
						<InputText v-else v-model="lockedProps['@values'].phone1" disabled id="phone1" type="text" />
					</div>

					<div class="p-field">
						<label for="email">E-Mail</label>
						<InputText v-if="!lockedProps.email" v-model="info.email" readonly id="email" type="text" />
						<InputText v-else v-model="lockedProps['@values'].email" disabled id="email" type="text" />
					</div>

				</div>
				<!-- {{ statusIntToStr(data.cardStatus,false) }} -->
				<h5>Carte physique</h5>
				<div class="p-fluid p-m-3">
					<div class="p-field">
						<label for="card_status">Statut de la carte</label>
						<CardStatusSelector :value="profile.cardStatus" @input="profile.cardStatus = $event"></CardStatusSelector>
					</div>

					<div v-show="profile.cardStatus === 4" class="p-field">
						<label for="url_suivi">Url suivi colis</label>
						<InputText v-model="urlSuivi" id="url_suivi" type="text" />
					</div>
				</div>

				<div class="p-field p-mt-6">
					<Button icon="pi pi-save"
							label="Mettre à jour !"
							@click="updateProfile"
							class="p-button"></Button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import ProfileService from "../../../service/ProfileService";
	import ProgressSpinner from 'primevue/progressspinner';
	import calendarMixin from "../../../mixins/calendarMixin";
	import userMixin from "../../../mixins/userMixin";

	import CardStatusSelector from "./CardStatusSelector.vue";

	export default {
		data(){
			return {
				loading: true,
				computedId: undefined,
				profile: undefined,
				info: undefined,
				lockedProps: undefined,
				urlSuivi: '',
			}
		},
		props: {
			id: {},
			apiId: {},
		},
		created(){
			this.profileService = new ProfileService();
			if(this.apiId !== undefined){
				this.computedId = this.apiId;
			}

			if(this.id === undefined){
				return;
			}
			this.computedId = '/api/users/' + this.id;
		},
		mounted(){
			if(this.computedId === undefined){
				return;
			}
			this.loadData();

		},
		methods: {
			updateProfile(){
				this.loading = true;

				const payload = {
					cardStatus: this.profile.cardStatus,
				};

				if(this.profile.cardStatus === 4){
					if(this.urlSuivi !== ''){
						payload.urlSuiviColis = this.urlSuivi;
					} else {
						payload.urlSuiviColis = null;
					}
				} else {
					payload.urlSuiviColis = null;
				}


				this.profileService.updateAdminUser(this.computedId, payload)
						.then(res => {
							if(res.data.error === true || (res.status !== 200)){
								throw new Error(res.data.message);
							}
							this.$toast.add({severity:'success', summary: 'Mise à jour réussie', detail: 'Utilisateur mits à jour !', life: 3000});
							this.$emit('update', {event: 'update', profile: this.profile});
						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							this.loading = false;
							this.loadData();
						});
			},
			loadData(){
				this.loading = true;
				this.profileService.getInfo(this.computedId)
						.then(res => {
							if(res.data.error === true || (res.status !== 200)){
								throw new Error(res.data.message);
							}
							this.profile = res.data;

							this.urlSuivi = this.profile.urlSuiviColis;
						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							if(this.profile !== undefined){
								this.loadSubset();
								return;
							}
							this.loading = false;
						});
			},
			loadSubset(){
				this.loading = true;
				this.profileService.getInfo(this.profile.info)
						.then(res => {
							if(res.data.error === true || (res.status !== 200)){
								throw new Error(res.data.message);
							}
							this.info = res.data;
						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							if(this.profile !== undefined){
								this.loadLocked();
								return;
							}
							this.loading = false;
						});
			},
			loadLocked(){
				this.loading = true;
				this.profileService.admin_getLockedProps(this.profile.id)
						.then(res => {
							if(res.data.error === true || (res.status !== 200)){
								throw new Error(res.data.message);
							}
							this.lockedProps = res.data;
						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							this.loading = false;
						});
			}
		},
		computed: {

		},
		mixins: [calendarMixin, userMixin],
		components: {
			ProgressSpinner,
			CardStatusSelector,
		}
	}
</script>

<style lang="scss">
	.user-profil {
		width: 100%;
		.p-title{
			font-size: 1.05rem;
			font-weight: bold;
		}
	}
</style>
