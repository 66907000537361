export default class PlanService {

    formatPlan(entity){

        let floatProps = ['priceVatExcl'];

        for(let i = 0; i < floatProps.length; i++){
            let prop = floatProps[i];
            if(entity[prop] !== undefined){
                entity[prop] = entity[prop] + '';
            }
        }

        return entity;
    }

    async updatePlan(linkApiId, payload) {
        payload = this.formatPlan(payload);
        return await window.appData.$http.put(linkApiId, payload)
            .then(res => {
                if(res.status !== 200 && res.status !== 201){
                    return {
                        status: res.status,
                        error: true,
                        data: res.data
                    };
                }

                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async deletePlan(linkApiId) {
        return await window.appData.$http.delete(linkApiId)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async createPlan(plan) {
        plan = this.formatPlan(plan);
        return await window.appData.$http.post('/api/plans', plan)
            .then(res => {
                if(res.status !== 200 && res.status !== 201){
                    return {
                        status: res.status,
                        error: true,
                        data: res.data
                    };
                }

                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async listPlans() {
        return await window.appData.$http.get('/api/plans')
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async getPlan(apiImageIp) {
        return await window.appData.$http.get(apiImageIp)
            .then(res => {
                return {
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }
}
