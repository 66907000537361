<template>
	<div class="p-fluid user-profil">
		<div  v-if="loading || profile === undefined" class="p-fluid p-field p-d-flex p-jc-center p-ai-center">
			<ProgressSpinner />
		</div>
		<div v-else class="p-fluid">
			<div class="p-fluid">
				<h5>Informations</h5>
				<div class="p-fluid p-m-3">
					<div class="p-field">
						<label for="email">Email</label>
						<InputText v-model="profile.username" id="email" type="text" />
					</div>
					<div class="p-field">
						<label v-bind:class="{'text-muted': profile.password === undefined || profile.password === ''}" for="password">Mot de passe
							<small class="text-muted">si rempli, remplacera le mot de passe actuel</small></label>
						<InputText v-model="pwd"  id="password" type="text" />
					</div>
				</div>

				<h5>Abonnement</h5>
				<div class="p-fluid p-m-3">
					<div v-if="profile.owner === null || profile.owner ===''" class="p-field">
						<label>Type de plan</label>
						<PlanSelector :value="profile.subPlan" @input="profile.subPlan = $event"></PlanSelector>
					</div>

					<div class="p-field">
						<label>Statut de l'utilisateur</label>
						<div class="p-field p-d-flex p-jc-left p-ai-center">
							<InputSwitch id="activeplan" v-model="profile.active" />
							<div class="p-as-center p-ml-2 text-muted">Utilisateur actif</div><br>
						</div>
					</div>

					<div  v-if="profile.owner === null || profile.owner ===''" class="p-field">
						<label>Expiration</label>
						<div class="p-fluid">
							<span v-if="profile.expireAt === null" class="text-muted">Sans limite &nbsp;</span>
							<span v-else class="text-muted">{{ tmsToDate(profile.expireAt) }} &nbsp;</span> <a @click.prevent="profile.timeDialog = true" href="#">modifier</a>
						</div>
						<Dialog v-model:visible="profile.timeDialog" :style="{width: '450px'}" header="Date d'expiration" :modal="true" :closable="true">

							<div class="p-field">
								<div class="p-field-radiobutton">
									<RadioButton name="permanente" :value="0" v-model="planExpireType" />
									<label for="permanente"><b>Sans expiration</b></label>
								</div>

								<div class="p-field-radiobutton">
									<RadioButton name="temporaire" :value="1" v-model="planExpireType" />
									<label for="temporaire"><b>Définir une expiration</b></label>
								</div>
							</div>

							<div class="p-field p-jc-center p-d-flex">
								<Calendar :disabled="planExpireType !== 1" v-model="expireAtValue" :touchUI="true" ></Calendar>
							</div>

							<template #footer>
								<div class="p-grid">
									<div class="p-col-6 p-align-center p-d-flex p-jc-center p-fluid">
										<Button icon="pi pi-times"
												label="Annuler"
												@click="cancelExpire"
												class="p-button-danger p-button-outlined"></Button>
									</div>
									<div class="p-col-6 p-align-center p-d-flex p-jc-center p-fluid">
										<Button icon="pi pi-save"
												label="Mettre à jour !"
												@click="applyExpire"
												class="p-button"></Button>
									</div>
								</div>
							</template>
						</Dialog>
					</div>

				</div>

				<h5>Fonctionnalités</h5>
				<div class="p-field p-m-3">
					<RoleEditor :value="profile.baseRoles" @input="profile.baseRoles = $event"></RoleEditor>
				</div>

				<div class="p-field p-mt-6">
					<Button icon="pi pi-save"
							label="Mettre à jour !"
							@click="updateProfile"
							class="p-button"></Button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import moment from 'moment';
	import ProfileService from "../../../service/ProfileService";
	import ProgressSpinner from 'primevue/progressspinner';
	import RoleEditor from './RoleEditor.vue';
	import PlanSelector from './PlanSelector.vue';
	import calendarMixin from "../../../mixins/calendarMixin";

	export default {
		data(){
			return {
				loading: true,
				computedId: undefined,
				profile: undefined,
				pwd: '',
				expireAt: undefined,
				planExpireType: 0,
				expireAtValue: undefined,
			}
		},
		props: {
			id: {},
			apiId: {},
		},
		created(){
			this.profileService = new ProfileService();
			if(this.apiId !== undefined){
				this.computedId = this.apiId;
			}

			if(this.id === undefined){
				return;
			}
			this.computedId = '/api/users/' + this.id;
		},
		mounted(){
			if(this.computedId === undefined){
				return;
			}
			this.loadData();

		},
		methods: {
			cancelExpire(){
				if(this.profile.expireAt === null){
					this.planExpireType = 0;
				} else {
					this.planExpireType = 1;
					let data = moment.unix(this.profile.expireAt);
					this.expireAtValue = data.toISOString();
				}
				this.profile.timeDialog = false;
			},
			applyExpire(){
				if(this.planExpireType === 0){
					this.profile.expireAt = null;
				} else {
					let data = moment(this.expireAtValue);
					this.profile.expireAt = data.unix();
				}
				this.profile.timeDialog = false;
			},
			updateProfile(){
				this.loading = true;

				if(this.pwd !== ''){
					this.profile.password = this.pwd;
				}

				this.profileService.updateAdminUser(this.computedId, this.profile)
						.then(res => {
							if(res.data.error === true || (res.status !== 200)){
								throw new Error(res.data.message);
							}
							this.$toast.add({severity:'success', summary: 'Mise à jour réussie', detail: 'Utilisateur mits à jour !', life: 3000});
							this.$emit('update', {event: 'update', profile: this.profile});
						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							this.loading = false;
							this.loadData();
						});
			},
			loadData(){
				this.loading = true;
				this.profileService.getInfo(this.computedId)
						.then(res => {
							if(res.data.error === true || (res.status !== 200)){
								throw new Error(res.data.message);
							}
							this.profile = res.data;

							this.cancelExpire();
						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							this.loading = false;
						});
			}
		},
		computed: {

		},
		mixins: [calendarMixin],
		components: {
			ProgressSpinner,
			RoleEditor,
			PlanSelector,
		}
	}
</script>

<style lang="scss">
	.user-profil {
		width: 100%;
		.p-title{
			font-size: 1.05rem;
			font-weight: bold;
		}
	}
</style>
