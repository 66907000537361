<template>
	<div class="selector input-field">
		<div v-if="dropdownValues !== undefined" class="w-100 selector-inner">
			<Dropdown v-model="innerValue" id="card_status" @change="updateValue(e)"
                      :options="dropdownValues" optionLabel="name" placeholder="Sélectionnez un status" />
		</div>
	</div>
</template>

<script>

import userMixin from "../../../mixins/userMixin";

export default {
	data() {
		return {
			linkTypesArray: [
				{
					name: this.statusIntToStr(0),
					code: 0,
				},
				{
					name: this.statusIntToStr(1),
					code: 1,
				},
				{
					name: this.statusIntToStr(2),
					code: 2,
				},
				{
					name: this.statusIntToStr(3),
					code: 3,
				},
				{
					name: this.statusIntToStr(4),
					code: 4,
				},
			],
			dropdownValues: undefined,
			innerValue: undefined,
		}
	},
	props: {
		value: {}
	},
	created() {

	},
	mounted() {
		const vm = this;
		this.innerValue = this.value;

		let arr = [];
		this.linkTypesArray.forEach(function(item){
			arr.push(item);
			if(item.code === vm.innerValue){
				vm.innerValue = item
			}
		});
		vm.dropdownValues = arr;
	},
	mixins: [userMixin],
	methods: {
		updateValue() {
			this.$emit('input', this.innerValue.code)
		}
	}
}
</script>

<style scoped>

</style>
